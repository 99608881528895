import classNames from "classnames";

import { Link } from "react-router-dom";

import styles from "./Card.module.css";

export const Card = ({
  title,
  description,
  href,
  isOldPaperBg,
  inCollections,
  className,
}) => {
  const cardClasses = classNames(
    styles.card,
    {
      [styles.oldPaperBg]: isOldPaperBg,
      [styles.blue]: title === "STARI DRUGARI",
      [styles.yellow]: title === "PERINO VESELO STARENJE",
      [styles.gray]: title === "ĆIRINA LEPOTA STARENJA",
      [styles.green]: title === "BAJINE MUDROSTI I LUDOSTI STARENJA",
      [styles.inCollections]: inCollections,
    },
    className
  );

  const textClasses = classNames(styles.text, {
    [styles.larger]: isOldPaperBg,
  });

  return (
    <div className={cardClasses}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && <p className={textClasses}>{description}</p>}
      {href && (
        <>
          <Link to={href} className={styles.link}>
            Pročitaj
            <i className="fa fa-arrow-right" style={{ paddingLeft: "8px" }}></i>
          </Link>
        </>
      )}
    </div>
  );
};
