import stariDrugari from "../assets/books/desktop/stari-drugari.pdf";
import baja from "../assets/books/desktop/baja-novo.pdf";
import pera from "../assets/books/desktop/pera.pdf";
import cira from "../assets/books/desktop/cira.pdf";
import stariDrugariMobile from "../assets/books/mobile/stari-drugari-mobile.pdf";
import peraMobile from "../assets/books/mobile/pera-mobile.pdf";
import ciraMobile from "../assets/books/mobile/cira-mobile.pdf";
import bajaMobile from "../assets/books/mobile/baja-novo-mobile.pdf";

export const collections = [
  {
    title: "STARI DRUGARI",
    description:
      "Ovo su jedine pesme koje ilustruju kako starost može biti isto tako poetski inspiritivna kao mladost. To su pesme o drugovima koji svoje stare dane mudro žive čuvajući radosti svoje mladosti",
    books: {
      desktop: stariDrugari,
      mobile: stariDrugariMobile,
    },
  },
  {
    title: "BAJINE MUDROSTI I LUDOSTI STARENJA",
    description:
      "Ovo su jedine pesme koje ilustruju kako starost može biti isto tako poetski inspiritivna kao mladost. To su pesme o Baji koji svoje stare dane mudro živi čuvajući radosti svoje ludosti.",
    books: {
      desktop: baja,
      mobile: bajaMobile,
    },
  },
  {
    title: "ĆIRINA LEPOTA STARENJA",
    description:
      "Ovo su jedine pesme koje ilustruju kako starost može biti isto tako poetski inspiritivna kao mladost. To su pesme o Ćiri koji svoje stare dane mudro živi čuvajući lepotu starenja",
    books: {
      desktop: cira,
      mobile: ciraMobile,
    },
  },
  {
    title: "PERINO VESELO STARENJE",
    description:
      "Ovo su jedine pesme koje ilustruju kako starost može biti isto tako poetski inspiritivna kao mladost. To su pesme o Peri koji svoje stare dane mudro živi čuvajući veselost u svom starenju",
    books: {
      desktop: pera,
      mobile: peraMobile,
    },
  },
];

export const qoutations = `Na svojstven način
Neprimetan i blag
Ostavio sam na svojim drugovima
Neizbrisiv trag

Neću više da mislim
O čemu neću da mislim
Hoću da mislim samo
O stvarima suvislim

Loše što je bilo
Nije mi vredno kajanja
Jer svaki život ima
Kratak rok trajanja

Sreća se
U životu ostvari
Kad je čine
Male stvari

Ja starim
I svoje godine brojim
Ja starim
Dakle postojim

Kažem sebi: Bilo šta
Da ti se desi
Uvek budi
Ono što jesi

Nisam primećivao
Dosadnu zbilju
Jer sam imao
Drugova u izobilju

Ono što kao samoća
Izgleda vama
To je moj izbor
Moja osama

Od kafane nema lepšeg običaja
Tu su razgovori puni sadržaja
Svaka priča ima značaja
Društvo ostaje do zadnjeg gutljaja

Naučio sam kako
Osmeh da probudim
Da tako nađem
Najkraći put do ljudi

Moj smeh je
Moj autogram
Moj duh je
Moj hologram

Drugarska pesma
I drugarska priča
To je putovanje
Bez vodiča

Ne volim sedenja dosadna
Ni priče banalne
Volim društvo za dušu
I pesme senzualne

Za meni drage ljude
Moj život je lepota
Jer razumeju gramatiku
Mog života

Lepše se sneva
Kada se peva
Bol se briše
Kad se smeje više

Želja mi se ostvari
Kad kroz male stvari
Kroz dobro jelo
Kroz društvo veselo
Kroz prijateljski razgovor
Ja sebi nađem odgovor

Drugarstvo je
Luka spasa
Kada živim
Bez kompasa

Nema više kafana
Samo brza hrana
Ima samo kafića
Za brza pića

Ja ne štedim
Na snovima
Već ih trošim
Dok ih još ima

Moje srce
Za starost ne mari
Jer moj duh
Nikad ne stari

Kad me prošlost stiže
Povuče me niže
Daje mi stabilnost
Ali mi koči mobilnost

Nikad nisam ponavljao
A voleo bih da sam bio ponavljač života
Fali mi ta lepota
Ta sentimentalna repriza
Da sebe iz mladosti ponovo vidim
Ali sada izbliza

Često želim da ostavim
U vazduhu jasan kao slika
Nasmešen otisak
Svog lika`;

export const songs = [
  {
    title: "BAJA O SVRSI ŽIVOTA",
    collection: "BAJINE MUDROSTI I LUDOSTI STARENJA",
    text: `Meni se moj život može 
Samo kroz pesme objasniti
Molim te pomozi mi, Bože,
Da svrhu života mogu razjasniti

Da li mi je sreća moja svrha
Ili što sam nekad stigao do vrha 
Ili da živim mirno i što duže
Ili da mi drugi mir i radost pruže

Jeli dobro što više nemam snova 
Ili što ništa ne bih živeo iznova 
Što osećam da sam se ostvario 
Mada sam se na izgled učaurio

Nisam danas leptir ali ni gusenica
Al’ da sam očuvan jeste činjenica
Ne odlažem život za bolje sutra 
Živim spolja ne zavlačim se unutra`,
  },
  {
    title: "KRUG ŽIVOTA",
    collection: "STARI DRUGARI",
    text: `Kaže Baja:
Pera se izlascima 
Potpuno preda
Osim kada je nežni deda
U svakoj supi
On je mirođija
Jer voli da glumi
Da je još bekrija
Ćira kaže:
Bajino omiljeno mesto
Sada je rasadnik
Otkad je postao
Politički neradnik
A protest on bira
Samo kad hoće da statira
Pera kaže:
Ćiri njegovo selo prija
Kaže tamo je
Njegova magija
U Vojnicima se
Njegov duh uzdiže
Jer veruje da tamo
On je raju bliže`,
  },
  {
    title: "POSETA DRUGOVA",
    collection: "STARI DRUGARI",
    text: `Cvetni dvorišni krugovi
Veseljem su ispunjeni
Jer došli su drugovi, Ćirini voljeni
Zato nova smirenost
U Ćirinom pogledu drema
Nestala je melanholija nema
Na stolu hleb i so
Jagnjeće pečenje seče
Biće to prekrasno veče
A onda osmeh širi od zagrljaja
Ulaze drugovi Slavko, Rade i Baja
Reči same teku
Razgovor ispunjen sećanjima
O nezaboravnim događajima
I zaboravljenim osećanjima
Prijatno druženje
Jagnjetina kao ispod sača
Vruć hleb iz pekare
I Bajina kruškovača
Puna im srca, reči beže u mladost
U zajedničke doživljaje
Da donesu davnu radost
I tako se u vojničkoj daljini
Sreća nastanila u Ćirinoj tišini`,
  },
  {
    title: "MARATONCI",
    collection: "STARI DRUGARI",
    text: `Jednog sunčanog dana
U Beogradu
U bašti restorana
U hladu
Utonjeni u priču bez kraja
Sede Ćira, Pera i Baja

Nemaju nove planove
U svojini imaju stanove
Otplatili životni dug
Maratonci sede poslednji krug

Guraju starost
Bez treme
Sačuvali su hrabrost
Da ignorišu vreme

Životna rutina
Baš im prija
To je sva
Njihova ambicija

Kažu, „Hvala ti, Bože
Živeti se može
Ništa nam ne fali
Daleko smo dogurali`,
  },
  {
    title: "O NAMA NEKAD",
    collection: "STARI DRUGARI",
    text: `Živeli smo nekako lakomisleno
Sutra nam je izgledalo besmisleno
Imali smo ideale poslovne
Nismo marili za one duhovne

S pogledom uprtim u visine
S mislima bez neke dubine
Imali smo sasvim obične želje
Puštali da nas život melje

Krivili smo druge bez mere
Tražili sreću bez prave vere
Hodali smo s puno buntovništva
Nije nam tada trebala molitva

Nama je ludost mladost dala
Krivine bez ikakvih ogledala
Kao da sve možemo ostvariti
Kao da nikad nećemo ostariti`,
  },
  {
    title: "ZNATI STARITI",
    collection: "STARI DRUGARI",
    text: `Starost dolazi
Sve najbolje prolazi
Potrebno je umeće
Za dalji put do sreće

Ćira, Pera i Baja
Još imaju sjaja
Što će taj put osvetliti
Pa će s dragima srećni biti

Ovim umetnicima starenja
Vreme k’o da se ne menja
Još ih drži stara energija
Još im je starost privilegija

Još imaju magnetizam
Nije im starost idiotizam
Još su dobrodušni i prisni
I u svojoj starosti nezavisni

Još vrednog od života mogu uzeti
Nalazeći načina da budu zauzeti
Još su srećni jer znaju i umeju
Da svakog oko sebe nasmeju

Nije im važno koliko stare
Već da dragima snove ostvare
Da se sa njima druže i vesele
I lepotu življenja tako podele`,
  },
  {
    title: "DAN KAO SAN",
    collection: "BAJINE MUDROSTI I LUDOSTI STARENJA",
    text: `Starost dođe
Mladost prođe

Vreme melje
Bajine želje

Negova brada
Seda sada

Još bi da spava
Budi ga java

Ustaje oran
Novi dan

Priroda se budi
Nigde ljudi

Još je snena
Jabuka rumena

Meso se suši
Baja lulu puši

Rakiju će da peče
Dok ne padne veče

A onda:
Utonuće umoran
U novi san`,
  },
  {
    title: "BAJA SA SLAMNIM ŠEŠIROM",
    collection: "BAJINE MUDROSTI I LUDOSTI STARENJA",
    text: `Baja izgleda
Još ko momak
Mada ima
Pivski stomak

Da ga ništa
Ni bi plašilo
Nosi slamni šešir
K’o strašilo

Ne zna šta je treće doba
Još bi drugo on da proba
Nije njemu do igara
Još bi hteo on da stvara

Da stvara bunt
Kad se šeta
Da menja prirodu
Preko leta

Doručak od suvog mesa
Da jede iz čistog besa
Sa dovoljno holesterola
Da ubije seoskog vola

Neće svom životu
Da bude sluga
Više voli
Da mu se ruga`,
  },
  {
    title: "BAJI ŽIVIOT TEČE",
    collection: "BAJINE MUDROSTI I LUDOSTI STARENJA",
    text: `Baja nema
Velike tajne
Njegove nade
Nisu beskrajne

Život mu teče
Svojim tokom
Nekad ubrzanom
Nikad dubokom

Sve mu je bilo
Kako je bilo
Ko da se snilo
Bilo pa se potrošilo

Zato mirno živi
I samo kaže
Živeću kako hoću
Ne kako drugi traže

Živeću život
Kako dođe
Doklegod traje
Dok ne prođe`,
  },
  {
    title: "BAJINE NAVIKE",
    collection: "BAJINE MUDROSTI I LUDOSTI STARENJA",
    text: `Baja se uvek
Plašio uroka
Plitak potok
A voda duboka

Baja voli da radi
I da nešto sadi
Zato što on, brale,
Ne hvata zjale

Baja dobro zna
Kako da bude sretan
I kad nam kaže:
„Ne znam, nisam pametan“

On nije lenj
I ima plan
I onda kad
Spava ko zaklan

Baja voli 
Odaje čiste
Jer nikad
Ne izvodi gliste

Ničeg se ne plaši
Ništa mi nije strašno
I nikad se ne smeje
Kao lud na brašno

Kad on svoju
Krsnu slavu sprema
Tada na stolu
Nema šta nema

Uvek je zadovoljan
I kaže sebi
Da je bolje
Valjalo ne bi

Zato kad ode
Sa društvom piti
Kaže: „Da nas nema
Trebalo bi nas izmisliti“

I kad svima
Naruči piće
Kaže: „Ko nas razume
Taj sve shvatiće“`,
  },
  {
    title: "ĆIRA O KAFANSKIM NOĆIMA",
    collection: "ĆIRINA LEPOTA STARENJA",
    text: `Sjaj davnih dana
Omiljena kafana
Društvo fino
Kuvano vino
Karirani stoljnaci
Moji podočnjaci
Dobri tamburaši 
Kelneri naši
Lice mi sija 
Domaća rakija
Jagnjeće pečenje
Za perkoračenje
Piće sručim
Pesmu poručim
Kafanske pesme
Kući se ne sme
Ne dam fajront
Napravim front
Zatvaraju kase
Svetla se gase
Ja se predajem
Polako ustajem
Odlazim s mirom
Na burek sa sirom`,
  },
  {
    title: "KAD ĆIRA …",
    collection: "ĆIRINA LEPOTA STARENJA",
    text: `Kad se od Ćire
Da odeš spremaš
On uvek pita:
„Da možda nemaš…?“

Kad se u Vojnicima
Čuje galama
Ćira uvek kaže:
„Budi Bog s nama“

Kad uživa
U tanjiru supe
Kaže legla je
Ko šaka na dupe

Da rastera društvo
Kad Ćira nastoji
On samo kaže:
„Kud koji, mili moji“

I pre nego što bi
Jagnjetinu narezo
On bi uvek kazo:
„Bog te mazo“

U protestima nije
Ni luk jeo ni luk miriso
Jer nikad nije
Vesla siso

Kad mu je milo
Ono što je bilo
On kaže:
„Bilo ono ko ludilo“

Kad se na nešto namerači
A ne zna da li će da ga zakači
On samo namigne i kaže:
„Mož da bidne ali ne mora da znači“`,
  },
  {
    title: "ĆIRINO OČEKIVANJE",
    collection: "ĆIRINA LEPOTA STARENJA",
    text: `Ćira luta hodnicima svoje samoće
Ogrnut mirom vojničke hladnoće
Samoća ga lenjo pozdravlja
Dok se on sam sa sobom raspravlja
Starosti senka dolazi nepozvana
Donosi događanja njemu neznana
Što će u njegovu baštu da padaju
I nove muke u životu da mu zadaju
U dvorištu zato misli sadi kao ruže
Iz svake ruže da po jedna nada nikne
Nada se da će tako živeti duže
Kad se na svoju starost privikne
Možda će časovi odsustva da odu
Da mu gosti vrate staru slobodu
I radost drugačijeg i boljeg sveta
Sigurnog kao hlad dudovog drveta
Da mu se desi neki nov početak
Da može da troši život ko imetak
Da se okupa u nečem dobrom
Da živi u harmoniji sam sa sobom`,
  },
  {
    title: "PERA TAMO AMO",
    collection: "PERINO VESELO STARENJE",
    text: `Pera kaže:
Krenuo sam onamo
Da se uputim
Kad ja tamo
A ono međutim

Zato odoh u kafić
Da vidim dobrog prijatelja
I raširim u zagrljaj ruke
Kao tramvaj zvani želja

Taj mi prijatelj kaže
Snimio me na televiziji
Ja mislim da on lupa
Ko Maksim po diviziji

Kad sam to čuo
Morao sam sesti
Jer to pas ni s maslom
Ne bi mogao pojesti
Zato sam od njega tražio
Da mi snimak donese
Moj ga zahtev malo potrese:
Pa reče: „Važi, čujemo se“

Na njega neću više
Trošiti vreme džabe
Jer on voli da laže
I da meša babe i žabe

Zato idem kući
Da sam ludujem
Dok svoje zadovoljstvo
Ja daunlodujem

Neki će drugi Pera
Ženama poglede da krade
Svojim širokim osmehom
Slatkim kao od marmelade`,
  },
  {
    title: "PERA I DRUŠTVO",
    collection: "PERINO VESELO STARENJE",
    text: `Pera i drugovi
Ništa ne rade
Svako od njih
Vreme krade

Idu u kafiće
Degustiraju piće
Idu i u kafane
U stare dane

Te sede glave
Vole da nazdrave
Kad nađu razlog
Da nešto slave

Oblače se nehajno
Ne interesuje ih moda
U mislima samo
Sport i razonoda

Uvek zajedno
Mladost sanjaju
Misle da tako
Besmrtnost ganjaju`,
  },
  {
    title: "PERINI SAVETI UNUCIMA",
    collection: "PERINO VESELO STARENJE",
    text: `Gledaj visoko
Misli duboko
Deli naširoko
Nastupaj žestoko

Pristupaj široko
Ne gledaj razroko
Ako si pametna
Bićeš sretna

Uvek iskoči
Gledaj u oči
Svemu doskoči
Da te ne koči

Budi svoja
Drži se uzgoja
Svog znoja
I svog spokoja`,
  },
];
