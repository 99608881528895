import { Outlet, useLocation } from "react-router-dom";
import { Header } from "../../components/Header";
import { Modal } from "../../components/Modal";
import { useEffect, useState } from "react";
import { Menu } from "../../components/Menu";

export const RootLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  const openModal = () => {
    setIsOpen(true);
    document.body.classList.add("body-scroll-lock");
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.classList.remove("body-scroll-lock");
  };

  return (
    <>
      <Header onClick={openModal} />
      <Modal isOpen={isOpen} onClose={closeModal}>
        <Menu onClick={closeModal} />
      </Modal>
      <Outlet key={location.pathname} />
    </>
  );
};
