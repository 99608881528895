import { useParams } from "react-router-dom";

import styles from "./Song.module.css";
import { Card } from "../../components/Card";
import { motion } from "framer-motion";

export const Song = ({ songs, collections }) => {
  const params = useParams();

  const [song] = songs.filter((song) => song.href === params.title);
  const [collection] = collections.filter(
    (collection) => collection.title === song.collection
  );

  console.log({ params });
  console.log({ songs });

  return (
    <motion.main
      className={styles.song}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.cardWrapper}>
        <Card title={collection.title} description={collection.description} />
      </div>
      <div className={styles.songWrapper}>
        <h1 className={styles.title}>{song.title}</h1>
        <div className={styles.text}>{song.text}</div>
      </div>
    </motion.main>
  );
};
