import styles from "./MenuIcon.module.css";

export const MenuIcon = ({ onClick }) => {
  return (
    <button className={styles.menuIcon} onClick={onClick}>
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
    </button>
  );
};
