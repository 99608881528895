import { collections, songs } from "../data/songs";
import { stringToUrl } from "./stringToUrl";

export const songTitlesMapped = songs.map((song) => {
  return {
    title: song.title,
    href: stringToUrl(song.title),
  };
});

export const songsMapped = songs.map((song) => {
  return {
    ...song,
    href: stringToUrl(song.title),
  };
});

export const collectionMapped = collections.map((collection) => {
  return {
    ...collection,
    href: stringToUrl(collection.title),
  };
});
