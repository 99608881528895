import { Link } from "react-router-dom";

import styles from "./Songs.module.css";
import { Card } from "../../components/Card";
import { motion } from "framer-motion";

export const Songs = ({ songs, collections }) => {
  return (
    <motion.main
      className={styles.songs}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h1 className={styles.title}>Primeri Pesama iz Zbirki</h1>
      <div className={styles.wrapper}>
        <div className={styles.cardWrapper}>
          {collections.map((collection) => (
            <Card
              title={collection.title}
              description={collection.description}
              key={collection.title}
            />
          ))}
        </div>
        <ul className={styles.list}>
          {songs.map((song) => (
            <li key={song.title} className={styles.item}>
              <Link className={styles.link} to={`/pesme/${song.href}`}>
                {song.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </motion.main>
  );
};
