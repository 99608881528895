import classNames from "classnames";

import styles from "./Modal.module.css";

export const Modal = ({ children, isOpen, onClose }) => {
  return (
    <div
      className={classNames(styles.modal, {
        [styles.open]: isOpen,
      })}
    >
      <div className={styles.heading}>
        <button onClick={onClose} className={styles.button}>
          X
        </button>
      </div>
      <div>{children}</div>
    </div>
  );
};
