import { MenuIcon } from "../MenuIcon";
import { Navigation } from "../Navigation";
import styles from "./Header.module.css";
import { motion } from "framer-motion";

export const Header = ({ onClick }) => {
  return (
    <motion.header
      className={styles.header}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.title}>MNovi</div>
      <MenuIcon onClick={onClick} />
      <Navigation />
    </motion.header>
  );
};
