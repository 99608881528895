export const routes = [
  {
    title: "Naslovna",
    href: "/",
  },
  {
    title: "Zbirke",
    href: "/zbirke",
  },
  {
    title: "Pesme",
    href: "/pesme",
  },
];
