import { NavLink } from "react-router-dom";
import { routes } from "../../routes/routes";
import styles from "./Menu.module.css";

export const Menu = ({ onClick }) => {
  return (
    <ul className={styles.list}>
      {routes.map((route) => (
        <li key={route.title} className={styles.item}>
          <NavLink
            to={route.href}
            end
            className={({ isActive }) => (isActive ? styles.active : undefined)}
            onClick={onClick}
          >
            {route.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
