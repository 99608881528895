import { Card } from "../../components/Card";
import styles from "./Homepage.module.css";
import { qoutations } from "../../data/songs";
import { motion } from "framer-motion";

export const Homepage = () => {
  return (
    <motion.main
      className={styles.homepage}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h1 className={styles.title}>JA STARIM, DAKLE POSTOJIM</h1>
      <Card description={qoutations} isOldPaperBg className={styles.card} />
    </motion.main>
  );
};
