import { Card } from "../../components/Card";
import styles from "./Collections.module.css";
import { motion } from "framer-motion";

export const Collections = ({ collections }) => {
  return (
    <motion.main
      className={styles.collections}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h1 className={styles.title}>
        ZNATI STARITI NA SVOJ NAČIN - Četiri zbirke pesama
      </h1>
      <div className={styles.cardWrapper}>
        {collections.map((collection) => (
          <Card
            {...collection}
            key={collection.title}
            inCollections
            href={`/zbirke/${collection.href}`}
          />
        ))}
      </div>
    </motion.main>
  );
};
