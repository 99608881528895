import { NavLink } from "react-router-dom";

import styles from "./Navigation.module.css";
import { routes } from "../../routes/routes";

export const Navigation = () => {
  return (
    <nav className={styles.navigation}>
      <ul className={styles.list}>
        {routes.map((route) => (
          <li key={route.title} className={styles.item}>
            <NavLink
              to={route.href}
              end
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
            >
              {route.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
