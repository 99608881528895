import { Viewer, Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import styles from "./PdfViewer.module.css";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { useWindowWidth } from "../../utils/useWindowWidth";

const PdfViewer = ({ collections }) => {
  const { windowWidth } = useWindowWidth();
  const params = useParams();

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,
    SwitchTheme: () => <></>,
    Open: () => <></>,
    ShowSearchPopover: () => <></>,
    Print: () => <></>,
  });

  const [collection] = collections.filter(
    (collection) => collection.href === params.title
  );

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
      <motion.main className={styles.container}>
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        <Viewer
          fileUrl={
            windowWidth >= 768
              ? collection.books.desktop
              : collection.books.mobile
          }
          plugins={[toolbarPluginInstance]}
        />
      </motion.main>
    </Worker>
  );
};

export default PdfViewer;
