import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import { Homepage } from "./containers/Homepage";
import { Collections } from "./containers/Collections/Index";
import { RootLayout } from "./containers/RootLayout";
import { ErrorMessage } from "./containers/ErrorMessage";
import { Songs } from "./containers/Songs";
import { Song } from "./containers/Song";
import { collections } from "./data/songs";
import {
  collectionMapped,
  songsMapped,
  songTitlesMapped,
} from "./utils/mapper";
import { AnimatePresence } from "framer-motion";
import PdfViewer from "./containers/PdfViewer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorMessage />,
    children: [
      { path: "/", element: <Homepage /> },
      {
        path: "/zbirke",
        element: <Collections collections={collectionMapped} />,
      },
      {
        path: "/zbirke/:title",
        element: <PdfViewer collections={collectionMapped} />,
      },
      {
        path: "/pesme",
        element: <Songs songs={songTitlesMapped} collections={collections} />,
      },
      {
        path: "/pesme/:title",
        element: <Song songs={songsMapped} collections={collections} />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="app">
      <AnimatePresence>
        <RouterProvider router={router} />
      </AnimatePresence>
    </div>
  );
}

export default App;
