import styles from "./ErrorMessage.module.css";
import sad from "../../assets/images/sad.png";
import { Link } from "react-router-dom";

export const ErrorMessage = () => {
  return (
    <div className={styles.errorMessage}>
      <img src={sad} alt="sad smiley" className={styles.img} />
      <div className={styles.textWrapper}>
        <h2 className={styles.title}>Ups, nešto nije u redu</h2>
        <p className={styles.text}>
          Žao nam je što morate da iskusite probleme! Molim vas vratite se na
          početnu stranicu <Link to="/">Naslovna</Link>
        </p>
      </div>
    </div>
  );
};
